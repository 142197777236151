import { makePath } from './utils'

const sellingPaths = {
  // promotion
  promotionPackages: makePath('/promotion/packages'),
  promotionPrimary: makePath('/promotion/package/primary'),

  promotionRealPass: makePath('/promotion/package/real-pass'),
  promotionGenrePass: makePath('/promotion/package/genre-pass'),
  promotionGenrePassDetail: makePath('/promotion/package/genre-pass/genre'),
  promotionClass101Pass: makePath('/promotion/class101'),
  promotionRealChallengeNow: makePath('/promotion/real-challenge-now'),
  promotionRealTokTok: makePath('/promotion/real-toktok'),
  promotionEvents: makePath('/promotion/events'),
  promotionEventDetail: makePath('/promotion/events/[eventId]'),
  promotionReview: makePath('/promotion/review'),

  promotionPackage: makePath('/promotion/package/[packageId]'),
  promotionGuide: makePath('/promotion/guide'),
  promotionB2B: makePath('/promotion/b2b'),
  promotionB2BConfirm: makePath('/promotion/b2b/confirm'),

  promotionAllInOnePass: makePath('/promotion/package/all-pass'),
  promotionAllInOnePassAlpha: makePath('/promotion/package/all-pass-alpha'),
  // payments
  payment: makePath('/payments'),
  paymentSuccess: makePath('/payments/result/success'),
  paymentFailure: makePath('/payments/result/failure'),
  paymentCallback: makePath('/payments/result/callback'),
}

export default sellingPaths
