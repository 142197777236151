import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useCheckUsageRight, useMe } from '@store/modules/user/swr'
import { useB2BContext } from '@contexts/b2b'
import usePaths from '@constants/paths'
import sellingPaths from '@constants/paths/selling'
import { getAccessToken } from '@utils/token'

function Root() {
  const router = useRouter()
  const paths = usePaths()
  const { data: userData } = useMe()
  const { data: usageRightData, isLoading } = useCheckUsageRight()
  const { isB2B } = useB2BContext()

  useEffect(() => {
    const token = getAccessToken()

    // 토큰이 없다면 이용권 판단하지 않고 셀링으로 이동
    // B2B는 클래스상세, 학습, 학습리포트, 고객지원 페이지만 진입이 가능하므로 셀링으로 이동시키지 않음
    if (!token && !isB2B) {
      return void router.replace(sellingPaths.promotionPrimary())
    }
  }, [])

  useEffect(() => {
    // 토큰이 있는 유저만 이 훅을 탄다.
    if (userData?.type === 'guest') return

    // B2B 유저는 이용권 판단하지 않고 404로 이동
    if (isB2B) return void router.replace('/404')

    // 아직 이용권을 확인하지 못했다
    if (!usageRightData || isLoading) return

    // 이용권이 있는 유저라면 학습 홈으로 이동
    if (usageRightData?.hasUsageRight) {
      return void router.replace(paths.studyHome())
    }
    // 이용권이 없다면 셀링으로 이동
    else {
      void router.replace(sellingPaths.promotionPrimary())
    }
  }, [userData, isLoading, usageRightData])

  return null
}

export default Root
